<template>
  <div class="app-container">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>试卷管理</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ name: 'checkList', params: { examId: examId } }">阅卷</el-breadcrumb-item>
        <el-breadcrumb-item>题目详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="app-contentTopic">
      <div class="scoring-sty">
        <p style="font-weight: bold;">参考人员</p>
        <hr class="hrStyle" />
        <div class="personal">
          <!--            employeeList-->
          <div
            class="indexDiv"
            @click="selecEmployeeData(em.questionVO.doRecord.id, em.questionVO, index)"
            v-for="(em, index) in employeeList"
            :class="IsF === em.questionVO.doRecord.id ? 'IsFocusDiv' : ''"
            :key="`em-${index}`"
          >
            <div>{{ index + 1 }}</div>
            <div class="abDiv" v-show="em.status === 1">批</div>
          </div>
          <!--            <div style="padding: 0 0 0 30px;height: 56px" v-for="(em, index) in employeeList" :key="`em-${index}`">-->
          <!--              <div @click="selecEmployeeData(em.questionVO.doRecord.id, em.questionVO)">{{index + 1}}、{{em.userName}}</div>-->
          <!--            </div>-->
        </div>
      </div>
      <div class="left">
        <div class="app-h">
          <span style="padding: 0 27px 0 0">剩余{{ qTotal }}个</span>
          <!--            <el-checkbox v-model="IsReverse" @change="reverse()">倒序</el-checkbox>-->
        </div>
        <div class="app-topicTitle" v-html="questionDetail.stem" />
        <div class="app-sAnswers">考生答案:&nbsp;&nbsp;<span class="answer" v-html="questionDetail.doRecord.answer || '暂未答题'" /></div>
        <div class="app-analy">解析:&nbsp;&nbsp;<span class="analysis" v-html="questionDetail.analysis || '暂无解析'" /></div>
      </div>
      <div class="right">
        <div class="pointTopic">
          <span>本题分数: {{ point }}分</span>
        </div>
        <div class="app-posAb">
          <span style="margin-bottom: 15px;">请打分:&nbsp;&nbsp;(默认满分)</span>
          <el-input-number v-model="questionDetail.points" :min="0" :max="point" placeholder="请设置分数" style="width: 150px" />
        </div>
        <div style="display: flex; width: 100%">
          <el-button :loading="Iswaiting" size="mini" style="flex-shrink: 1" type="primary" @click="nextQById(questionDetail)">打分</el-button>
          <el-button @click="backRoute" style="flex-shrink: 1" size="mini">返回</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import review from '../../../api/review'
import { handleParams } from '../../../util/util'

export default {
  name: 'revieTopic',
  data() {
    return {
      em: 0,
      point: 10,
      qTotal: 0,
      IsF: null,
      employeeList: [],
      questionDetail: {
        points: null,
        analysis: null,
        doRecord: {
          answer: null
        }
      },
      Iswaiting: false,
      IsHoverRef: false,
      IsReverse: false,
      examId: 0,
      questionId: 0
    }
  },
  created() {
    let res = this.$handleParams('examCheckData')
    this.examId = res.examId
    this.questionId = res.questionId
    this.getAnsweremployee(this.examId, false, this.questionId)
    this.getQTotal(this.examId, this.questionId, false)
  },
  methods: {
    getAnsweremployee(examId, reverse, qId) {
      review.getAllAnswerEmployee(examId, reverse, qId).then(res => {
        this.employeeList = res.res
        this.employeeList.map(item => {
          item.status = 0
        })
        if (this.employeeList.length > 0) {
          this.questionDetail = this.employeeList[0].questionVO
          this.em = 0
          this.IsF = this.employeeList[0].questionVO.doRecord.id
        } else {
          this.message('试卷待批阅题目已完成,即将返回阅卷页面', 'warning')
          this.$router.back()
        }
      })
    },
    selecEmployeeData(id, data, em) {
      this.em = em
      this.questionDetail = data
      this.IsF = id
    },
    getQTotal(id, questionId, reverse) {
      review.reviewExamQTotal(id, questionId, reverse).then(res => {
        this.qTotal = res.res
      })
    },
    backRoute() {
      this.$router.push({
        name: 'checkList',
        params: { examId: this.examId.toString() }
      })
    },
    nextQById(question) {
      this.Iswaiting = true
      let payload = {
        answerPaperId: question.doRecord.answerPaperId,
        questionId: question.doRecord.questionId,
        recordId: question.doRecord.id,
        score: question.points
      }
      review
        .recordPointByQId(payload)
        .then(res => {
          if (res.res) {
            this.message('成功', 'success')
            this.$set(this.employeeList[this.em], 'status', 1)
            if (this.em + 1 < this.employeeList.length) {
              this.em = this.em + 1
              this.questionDetail = this.employeeList[this.em].questionVO
              this.IsF = this.employeeList[this.em].questionVO.doRecord.id
            }
            // this.getAnsweremployee(this.examId, false, this.questionId)
          } else {
            this.message('打分失败', 'warning')
          }
        })
        .catch(() => {
          // this.message('打分失败123', 'warning')
        })
        .finally(() => {
          this.getQTotal(this.examId, this.questionId, false)
          this.Iswaiting = false
        })
    },
    message(msg, type) {
      this.$message({ message: msg, type: type, duration: 800 })
    }
  }
}
</script>

<style lang="less" scoped></style>
